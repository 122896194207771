import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { DefaultSeo } from 'next-seo';


export default function App({ Component, router, pageProps: { session, ...pageProps}, }: AppProps) {
  return (
    <>
      <DefaultSeo
        title='Green Parenting | Raise a baby in an eco-friendly environment'
        titleTemplate='%s'
        description='Creating a healthy environment with high quality and the most finest baby and mom products.'
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: process.env.NEXT_PUBLIC_HOSTNAME,
          siteName: 'Green Parenting website',
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.png'
          }
        ]}
      />
      {process.env.NODE_ENV === 'production' && (
        <GoogleAnalytics
          strategy='lazyOnload'
          trackPageViews={{ ignoreHashChange: true }}
        />
      )}
      <SessionProvider session={pageProps.session}>
        <Component {...pageProps} key={router.asPath} />
      </SessionProvider>
    </>
  )
}
